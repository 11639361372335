import { WORKFLOW_TEMPLATES_SET } from "../constants/workflowTemplates";

function set(workflowTemplates) {
  return {
    type: WORKFLOW_TEMPLATES_SET,
    workflowTemplates,
  };
}

const workflowTemplateActions = {
  set,
};

export default workflowTemplateActions;

import React from "react";
import { Switch } from "react-router-dom";

import AccountPage from "../../pages/AccountPage";
import AppLayout from "../AppLayout";
import HomeLayout from "../HomeLayout";
import LoginPage from "../../pages/LoginPage";
import MarketingPage from "../../pages/MarketingPage";
import TimersPage from "../../pages/TimersPage";

const Routes = props => (
  <Switch>
    <HomeLayout exact path="/" component={MarketingPage} authenticatedComponent={TimersPage} {...props} />
    <AppLayout path="/login" component={LoginPage} {...props} />
    <AppLayout isProtected path="/account" component={AccountPage} {...props} />
  </Switch>
);

export default Routes;

import React, { Component } from "react";
import { View } from "react-native";
import styled from "styled-components";

import MailchimpSubscribe from "react-mailchimp-subscribe";

import Button from "../Button";
import EmailInput from "../EmailInput";

const FormWrapper = styled(View)`
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
  flex-direction: row;
  font-family: ${props => props.theme.font.body};

  form {
    display: flex;
    flex: 1;

    @media (max-width: 768px) {
      display: block;
    }
  }

  @media (max-width: 768px) {
    box-shadow: none;
    flex-direction: column;
  }
`;

const MessageWrapper = styled.div`
  font-family: ${props => props.theme.font.body};
  padding: 1em 0;

  a {
    color: ${props => props.theme.color.black};
    font-weight: bold;
    text-decoration: none;
  }
`;

const StyledButton = styled(Button)`
  background-color: ${props => props.theme.color.secondary};
  border-color: ${props => props.theme.color.secondary};
  border-radius: 0;
  color: ${props => props.theme.color.white};
  font-size: 1rem;
  flex-shrink: 0;
  letter-spacing: 0.1em;
  line-height: 1.25;
  padding: 1rem;
  text-transform: uppercase;

  &:hover {
    border-color: transparent;
    box-shadow: none;
  }

  @media (max-width: 768px) {
    width: 100%;
  };
`;

const StyledEmailInput = styled(EmailInput)`
  border: none;
  line-height: 1.25;
  margin-bottom: 0;
  outline: none;
  padding: 1rem;

  ::-webkit-input-placeholder, ::placeholder {
    color: #808790;
    font-weight: 300;
  }

  @media (max-width: 768px) {
    border: solid 1px ${props => props.theme.color.black};
    margin-bottom: 10px;
  };
`;

class SubscriptionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailAddress: "",
      url: "//brilliantfantastic.us8.list-manage.com/subscribe/post?u=c55eb6cd0c1edbe2a7ef07e7b&amp;id=5f79572557",
    };
  }

  handleEmailAddressChange = (event) => {
    const { target: { value: emailAddress } } = event;
    this.setState({ emailAddress });
  }

  handleSubmit = (event, subscribe) => {
    event.preventDefault();

    const { emailAddress } = this.state;

    subscribe({ EMAIL: emailAddress });
  }

  render() {
    const { url } = this.state;

    return (
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => {
          const sending = status === "sending";

          return (
            <React.Fragment>
              <FormWrapper>
                <form onSubmit={e => this.handleSubmit(e, subscribe)}>
                  <StyledEmailInput
                    placeholder="focused@example.com"
                    onChange={this.handleEmailAddressChange}
                  />
                  <StyledButton disabled={sending}>
                    {sending ? "Sending..." : "Get in Line"}
                  </StyledButton>
                </form>
              </FormWrapper>
              {message && (
                <MessageWrapper dangerouslySetInnerHTML={{ __html: message }} />
              )}
            </React.Fragment>
          );
        }}
      />
    );
  }
}

export default SubscriptionForm;

import styled from "styled-components";

const H3 = styled.h3`
  font-family: ${props => props.theme.font.primary};
  font-size: 1.125rem;

  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`;

export default H3;

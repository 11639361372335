import React from "react";
import PropTypes from "prop-types";

import AppLayout from "../AppLayout";
import MarketingLayout from "../MarketingLayout";

import { withAuthentication } from "../../enhancers/withAuthentication";

export const HomeLayout = ({ authenticatedComponent, component, ...rest }) => {
  const { isLoggedIn } = rest;

  return isLoggedIn ? <AppLayout component={authenticatedComponent} {...rest} />
    : <MarketingLayout component={component} {...rest} />;
};

HomeLayout.defaultProps = {
  authenticatedComponent: null,
  component: null,
};

HomeLayout.propTypes = {
  authenticatedComponent: PropTypes.func,
  component: PropTypes.func,
};

export default withAuthentication(HomeLayout);

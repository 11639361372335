import styled from "styled-components";

const H2 = styled.h2`
  font-family: ${props => props.theme.font.primary};
  font-size: 1.5rem;

  @media (min-width: 768px) {
    font-size: 1.875rem;
  }
`;

export default H2;

import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

import Col from "../Col";
import Grid from "../Grid";
import LogoLink from "../LogoLink";
import MarketingFooter from "../MarketingFooter";
import MarketingHeader from "../MarketingHeader";
import Row from "../Row";

export const MarketingLayout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <React.Fragment>
        <Grid>
          <Row>
            <Col xs={6}>
              <LogoLink href="/">
                Chronic.
              </LogoLink>
            </Col>
            <MarketingHeader {...props} />
          </Row>
        </Grid>
        <Component {...props} />
        <MarketingFooter {...props} />
      </React.Fragment>
    )}
  />
);

MarketingLayout.defaultProps = {
  component: null,
};

MarketingLayout.propTypes = {
  component: PropTypes.func,
};

export default MarketingLayout;

import styled from "styled-components";

import InlineList from "../InlineList";

const NavList = styled(InlineList)`
  line-height: 74px;
  text-align: right;
  vertical-align: middle;

  li {
    height: 50px;
    line-height: 50px;
  }

  li a {
    padding: 14px 16px;
  }
`;

export default NavList;

import React from "react";
import PropTypes from "prop-types";

import { withAuthentication } from "../../enhancers/withAuthentication";

import Alert from "../Alert";
import Clock from "../Clock";
import Col from "../Col";
import NavList from "../NavList";
import NotificationBell from "../NotificationBell";
import UserMenu from "../UserMenu";

const AuthHeader = ({ alert, isLoggedIn }) => (
  <React.Fragment>
    <Col xs={false} md={6}>
      <NavList>
        <li>
          <NotificationBell />
          {alert && (
          <Alert type={alert.type}>
            {alert.message}
          </Alert>
          )}
        </li>
        <li>
          <Clock />
        </li>
        {isLoggedIn
          && (
          <li>
            <UserMenu />
          </li>
          )
        }
      </NavList>
    </Col>
    <Col xs={6} md={false}>
      <NavList>
        {isLoggedIn
          && (
          <li>
            <UserMenu />
          </li>
          )
        }
      </NavList>
    </Col>
  </React.Fragment>
);

AuthHeader.propTypes = {
  alert: PropTypes.shape({
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  isLoggedIn: PropTypes.bool.isRequired,
};

AuthHeader.defaultProps = {
  alert: null,
};

export default withAuthentication(AuthHeader);

import camelizer from "./camelizer";

export default class WorkflowReducer {
  constructor(templates, currentSteps = []) {
    this.templates = templates;
    this.currentSteps = currentSteps;
  }

  reduce() {
    return this.templates.map((template) => {
      const existingStep = this.findStep(template.id);

      const currentStep = existingStep
        ? WorkflowReducer.existingWorkflow(existingStep)
        : WorkflowReducer.templateWorkflow(this.currentTemplate());

      return { id: currentStep.id, templateId: template.id, currentStep };
    });
  }

  currentTemplate() {
    // For now, just return the first workflow template.
    // In the near future, the current workflow will be saved on
    // the server and this collection will have a flag to mark the current one
    return this.templates[0];
  }

  findStep(templateId) {
    return this.currentSteps.find(step => step.template_id === templateId);
  }

  static templateWorkflow(template) {
    const templateStep = template.steps.sort((left, right) => left.position - right.position)[0];

    return Object.assign({
      id: 0,
      templateId: template.id,
      remainingTime: templateStep.duration,
      status: "stopped",
    }, templateStep);
  }

  static existingWorkflow(step) {
    const workflow = camelizer.humpDay(step);
    const { currentStep } = workflow;

    delete workflow.currentStep;

    return Object.assign({ ...workflow }, { ...currentStep });
  }
}

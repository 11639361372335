import React from "react";
import { Text, View } from "react-native";
import styled from "styled-components";
import PropTypes from "prop-types";

import Col from "../../components/Col";
import Grid from "../../components/Grid";
import H1 from "../../components/H1";
import H2 from "../../components/H2";
import H3 from "../../components/H3";
import P from "../../components/P";
import Row from "../../components/Row";
import SubscriptionForm from "../../components/SubscriptionForm";

const A = styled.a`
  color: ${props => props.theme.color.white};
  font-weight: bold;
  text-decoration: none;
`;

const BodyText = styled(Text)`
  color: #636363;
  font-size: 1rem;
  font-smooth: antialiased;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  word-spacing: 0.02em;

  p {
    line-height: 1.5;
    margin-bottom: 2rem;
  }

  @media (min-width: 768px) {
    font-size: 1.25rem;
  }

  @media (min-width: 960px) {
    font-size: 1.38rem;

    p {
      margin-bottom: 2.5rem;
    }
  }
`;

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @media (min-width: 576px) {
    max-width: 576px;
  }

  @media (min-width: 768px) {
    max-width: 768px;
  }

  @media (min-width: 960px) {
    max-width: 960px;
  }
`;

const Content = styled.div`
  padding-bottom: 6rem;
  width: 100%;

  @media (min-width: 960px) {
    width: 83.33333%;
  }
`;

const Emphasis = styled.span`
  background: linear-gradient(to right, #fce4ed, #ffe8cc);
  font-weight: 700;
  line-height: 1.5rem;
  padding: 0.3rem;
  -webkit-gradient(linear, left top, right top, from(#fce4ed), to(#ffe8cc));
`;

const FeatureCard = styled(View)`
  background-color: #FFFBEA;
  background-repeat: no-repeat;
  height: 350px;
  margin: 0;
  padding: 1rem;

  p {
    color: #636363;
  }

  @media (max-width: 960px) {
    margin-bottom: 3rem;
  }
`;

const FeatureCard1 = styled(FeatureCard)`
  background-image: url("images/feature1.svg");
  background-position: 85% 95%;
  background-size: auto 40%;
`;

const FeatureCard2 = styled(FeatureCard)`
  background-image: url("images/feature2.svg");
  background-position: bottom;
  background-size: auto 40%;
`;

const FeatureCard3 = styled(FeatureCard)`
  background-image: url("images/feature3.svg");
  background-position: 15% bottom;
  background-size: auto 40%;
`;

const Tagline = styled(P)`
  border-left: solid 3px ${props => props.theme.color.secondary};
  font-size: 1.25rem;
  line-height: 1.25;
  padding-left: 1.25rem;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }

  @media (min-width: 960px) {
    border-left-width: 5px;
    font-size: 1.875rem;
  }
`;

const FAQs = styled.ul`
  color: #E8E6E1;
  list-style: none;
`;

const AnswerWrapper = styled(Col)`
  color: ${props => props.theme.color.white};
  font-family: ${props => props.theme.font.body};
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.5;
  padding-left: 1.5rem;

  @media (max-width: 960px) {
    padding-left: 0;
  }
`;

const Answer = ({ answer }) => (
  <AnswerWrapper xs={12} md={8}>
    {answer}
  </AnswerWrapper>
);

Answer.propTypes = {
  answer: PropTypes.node.isRequired,
};

const QuestionWrapper = styled(Col)`
  font-family: ${props => props.theme.font.body};
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 0.06rem;

  @media (max-width: 960px) {
    letter-spacing: normal;
    margin-bottom: 1rem;
    padding: 0;
  }
`;

const Question = ({ question }) => (
  <QuestionWrapper xs={12} md={4}>
    {question}
  </QuestionWrapper>
);

Question.propTypes = {
  question: PropTypes.node.isRequired,
};

const FAQItem = styled(Row)`
  padding: 2rem 0;
`;

const FAQ = ({ question, children }) => (
  <li>
    <FAQItem>
      <Question question={question} />
      <Answer answer={children} />
    </FAQItem>
  </li>
);

FAQ.propTypes = {
  children: PropTypes.node.isRequired,
  question: PropTypes.string.isRequired,
};

const MarketingPage = () => (
  <React.Fragment>
    <div>
      <Grid>
        <Container>
          <Content>
            <H1>Time will soon be on your side</H1>
            <BodyText>
              <Tagline>Have you ever thought &quot;what did I get done today&quot;?</Tagline>
              <P>
                Focus is
                {" "}
                <em>really</em>
                {" "}
                hard these days, especially with all of the dings, red circles, and
                {" "}
                &quot;notification centers&quot;.
              </P>
              <P>
                <Emphasis>
                  We are building a focus timer that helps you reclaim your efficiency.
                </Emphasis>
                {" "}
                Chronic helps you get things done whether it is in 25-minute Pomodoro chunks or
                {" "}
                2-hour deep work stints. However you want to work, it will keep you on task.
              </P>
              <P>
                We have tried all of the Pomodoro timers. Literally all of them.
                {" "}
                No one has built one that helps facilitate a sense of fulfillment of your day.
                {" "}
                We are here to change that.
              </P>
              <P>
                Chronic works wherever you work. Chronic helps you focus alongside your team.
                {" "}
                Chronic will support a powerful set of integrations.
              </P>
              <P>
                <strong>If you are tired from working insane hours</strong>
                {" "}
                and want to get back to a healthier, efficient work life, drop in your
                {" "}
                email address and we will let you know when Chronic is ready to help.
              </P>
            </BodyText>
            <SubscriptionForm />
          </Content>
        </Container>
      </Grid>
    </div>
    <div style={{ backgroundColor: "#E8E6E1" }}>
      <Grid>
        <Container>
          <Content style={{ paddingTop: "2rem" }}>
            <H2 style={{ marginBottom: "3rem" }}>Features to look forward to</H2>
            <Row between="xs">
              <Col xs={12} md={4}>
                <FeatureCard1>
                  <H3>A timer that works where you do</H3>
                  <P>
                    Chronic is a timer in the sky. It moves where you move.
                    {" "}
                    All your devices will be in sync whether you are working or on a break.
                  </P>
                </FeatureCard1>
              </Col>
              <Col xs={12} md={4}>
                <FeatureCard2>
                  <H3>Work together in a focused way</H3>
                  <P>
                    Pairing on some work or want to all eat lunch together?
                    {" "}
                    Send your pair or your team a link and sync up your timers.
                  </P>
                </FeatureCard2>
              </Col>
              <Col xs={12} md={4}>
                <FeatureCard3>
                  <H3>Take your services with you</H3>
                  <P>
                    Chronic will integrate with your favorite time tracking or task management apps.
                    {" "}
                    We have a few neat integrations planned as well.
                  </P>
                </FeatureCard3>
              </Col>
            </Row>
          </Content>
        </Container>
      </Grid>
    </div>
    <div style={{ backgroundColor: "#B8B2A7" }}>
      <Grid>
        <Container>
          <Content style={{ paddingTop: "2rem" }}>
            <H2 style={{ marginBottom: "3rem" }}>Questions you may be asking</H2>
            <FAQs>
              <FAQ question="What are you building?">
                A focus timer that we have wanted to exist for the past five years.
                No one has built it yet, so we are out on a mission to make it a reality.
                It&apos;s the most ambitious project we have worked on and we think it will be the
                best tool to help you focus.
              </FAQ>
              <FAQ question="Who is behind this?">
                We are two developers from the mid-west who build tools we want to use.
                {" "}
                <A href="https://twitter.com/jwright" target="_blank" rel="noopener noreferrer">Jamie</A>
                {" "}
is from Toledo, Ohio and
                {" "}
                <A href="https://twitter.com/keiththomps" target="_blank" rel="noopener noreferrer">Keith</A>
                {" "}
is from Ft. Wayne, Indiana.
              </FAQ>
              <FAQ question="What platforms will Chronic launch on?">
                Chronic will launch with an iOS app, an Android app, a Mac app,
                {" "}
                and this here web app.
                {" "}
                There may be an additional secret platform that we will launch with
                {" "}
                but you have to get on the beta to get access.
              </FAQ>
              <FAQ question="Can I start using it?">
                Not yet. We are on a closed Alpha but we will be rolling out a Beta release
                {" "}
                in cohorts starting in a few months.
              </FAQ>
              <FAQ question="Can I get in line for the Beta release?">
                You sure can. We will be emailing you in a few months on a first-come first-serve
                {" "}
                basis to see if you would like
                {" "}
                to join in the current beta cohort.
              </FAQ>
              <FAQ question="Why is it taking so long?">
                &apos;Cause we each have real day jobs and this is a large side project and
                {" "}
                we are sweating the details. It will be worth the wait.
              </FAQ>
              <FAQ question="Do you have a podcast where you talk about building Chronic?">
                Glad you asked. We sure do. It&apos;s called
                {" "}
                <A href="http://standup.fm" target="_blank" rel="noopener noreferrer">The Standup</A>
                {" "}
                and can be found in iTunes or wherever you get your podcasts.
              </FAQ>
            </FAQs>
          </Content>
        </Container>
      </Grid>
    </div>
  </React.Fragment>
);

export default MarketingPage;

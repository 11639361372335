import styled from "styled-components";

const TextArea = styled.textarea`
  border: solid 1px #000;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
`;

export default TextArea;

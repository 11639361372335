import {
  CLEAR_ALERT,
  ERROR_ALERT,
  INFO_ALERT,
  SUCCESS_ALERT,
} from "../constants/alert";

function clear() {
  return { type: CLEAR_ALERT };
}

function info(message) {
  return { type: INFO_ALERT, message };
}

function error(message) {
  return { type: ERROR_ALERT, message };
}

function success(message) {
  return { type: SUCCESS_ALERT, message };
}

const alertActions = {
  clear,
  error,
  info,
  success,
};

export default alertActions;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect as connectRedux } from "react-redux";

import TimerComponent from "../../components/Timer";

import workflowsActions from "../../actions/workflows";
import { withAuthentication } from "../../enhancers/withAuthentication";

export class Timer extends Component {
  handlePause = () => {
    const { pause, id } = this.props;
    pause({ id });
  }

  handleStart = () => {
    const {
      start,
      resume,
      id,
      templateId,
      currentStep: { status },
    } = this.props;

    if (status === "stopped") {
      start({ templateId });
    } else if (status === "paused") {
      resume({ id });
    }
  }

  render() {
    return (
      <TimerComponent
        onPause={this.handlePause}
        onStart={this.handleStart}
        {...this.props}
      />
    );
  }
}

Timer.propTypes = {
  currentUser: PropTypes.shape({
    apiToken: PropTypes.string,
  }).isRequired,
  pause: PropTypes.func.isRequired,
  start: PropTypes.func.isRequired,
  resume: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  // TODO: Make this a string after the server is changed
  templateId: PropTypes.number.isRequired,
  currentStep: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
};

const mapDispatchToProps = dispatch => ({
  pause: (params) => {
    dispatch(workflowsActions.pause(params.id));
  },
  resume: (params) => {
    dispatch(workflowsActions.resume(params.id));
  },
  start: ({ templateId }) => {
    dispatch(workflowsActions.start(templateId));
  },
});

export default connectRedux(null, mapDispatchToProps)(withAuthentication(Timer));

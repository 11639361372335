import { SET_CONNECTION } from "../constants/connection";
import { PAUSE_WORKFLOW, RESUME_WORKFLOW, START_WORKFLOW } from "../constants/workflows";

export function connection(state = {}, action) {
  function send(event, payload) {
    const { socket } = state;

    if (socket) {
      socket.send(JSON.stringify([event, payload]));
    }
  }

  switch (action.type) {
    case PAUSE_WORKFLOW:
      send("workflows:pause", { id: action.id });
      return state;
    case RESUME_WORKFLOW:
      send("workflows:resume", { id: action.id });
      return state;
    case SET_CONNECTION:
      return action.connection;
    case START_WORKFLOW:
      send("workflows:start", { id: action.templateId });
      return state;

    default:
      return state;
  }
}

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import styled from "styled-components";

import alertActions from "../../actions/alert";

/* eslint-disable react/destructuring-assignment */
const AlertText = styled.div`
  color: ${props => props.theme.color.alert[props.type].foreground};
  padding: 0 5px;

  &.hide {
    display: none;
  }
`;

const AlertWrapper = styled.div`
  display: inline-block;
  font-family: ${props => props.theme.font.body};
  max-width: 200px;
  text-overflow: ellipsis;
  transition: width 0.5s linear;
  width: 0%;
  white-space: nowrap;

  &.alerted {
    width: 100%;
  }
`;

let displayAlert;

class Alert extends Component {
  constructor(props) {
    super(props);

    this.state = { show: false };
  }

  componentDidMount() {
    const { dispatch, children } = this.props;
    const element = document.getElementById("alert-wrapper");
    element.addEventListener("transitionend", () => {
      Array.from(element.getElementsByClassName("hide")).forEach(
        el => el.classList.remove("hide"),
      );
      setTimeout(() => dispatch(alertActions.clear()), 2000);
    });

    displayAlert = setTimeout(() => {
      this.setState({ show: (children && children.length > 0) });
    }, 1);
  }

  componentWillUnmount() {
    if (displayAlert) {
      clearTimeout(displayAlert);
    }
  }

  render() {
    const { children } = this.props;
    const { show } = this.state;

    return (
      <AlertWrapper id="alert-wrapper" className={show ? "alerted" : null}>
        <AlertText className="hide" {...this.props}>
          {children}
        </AlertText>
      </AlertWrapper>
    );
  }
}

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const withConnect = connect();
export default compose(withConnect)(Alert);

import React, { Component } from "react";
import styled from "styled-components";
import Moment from "react-moment";
import PropTypes from "prop-types";

const TimeSpan = styled.span`
  background-color: #000;
  border-radius: 3px;
  font-family: ${props => props.theme.font.body};
  color: #fff;
  padding: 3px 5px;
`;

let tickTimer;

class Clock extends Component {
  componentDidMount() {
    const { interval } = this.props;

    tickTimer = setInterval(() => {
      this.forceUpdate();
    }, interval);
  }

  componentWillUnmount() {
    if (tickTimer) {
      clearInterval(tickTimer);
    }
  }

  static getCurrentDate() {
    return new Date();
  }

  render() {
    const { format } = this.props;

    return (
      <TimeSpan>
        <Moment format={format}>
          {Clock.getCurrentDate()}
        </Moment>
      </TimeSpan>
    );
  }
}

Clock.propTypes = {
  format: PropTypes.string,
  interval: PropTypes.number,
};

Clock.defaultProps = {
  format: "h:mm a",
  interval: 1,
};

export default Clock;

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export const withAuthentication = (WrappedComponent) => {
  const AuthenticationHOC = (props) => {
    const { user: currentUser } = props;
    const isLoggedIn = !!(currentUser && currentUser.apiToken);
    const isLoggedOut = !isLoggedIn;

    return (
      <WrappedComponent
        currentUser={currentUser}
        isLoggedIn={isLoggedIn}
        isLoggedOut={isLoggedOut}
        {...props}
      />
    );
  };

  AuthenticationHOC.defaultProps = {
    user: null,
  };

  AuthenticationHOC.propTypes = {
    user: PropTypes.shape({
      apiToken: PropTypes.string,
    }),
  };

  function mapStateToProps(state) {
    const { user } = state;

    return {
      user,
    };
  }

  return connect(mapStateToProps)(AuthenticationHOC);
};

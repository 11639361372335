import React from "react";
import styled from "styled-components";

import InputStyles from "../../styles/Input";
import { withChangeText } from "../../enhancers/withChangeText";

const StyledInput = styled.input`
  ${InputStyles}
`;

const EmailInput = props => (
  <StyledInput type="email" {...props} />
);

export default withChangeText(EmailInput);

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect as connectRedux } from "react-redux";

import { withAuthentication } from "../../enhancers/withAuthentication";
import * as Services from "../../services";

import Timer from "../Timer";

export class Timers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      connected: false,
    };
  }

  async componentDidMount() {
    const { connect, currentUser } = this.props;

    await connect(currentUser);
    this.setState({ connected: true });
  }

  render() {
    const { connected } = this.state;
    const { workflows, workflowTemplates } = this.props;

    if (!connected) return null;

    return (
      <React.Fragment>
        { workflows.map((workflow) => {
          // TODO: Make template.id a string and remove the toString here
          const template = workflowTemplates.find(
            t => t.id.toString() === workflow.templateId.toString(),
          );
          const templateName = template ? template.name : "";

          return (
            <Timer
              key={workflow.id}
              workflow={workflow}
              templateName={templateName}
              {...workflow}
            />
          );
        }) }
      </React.Fragment>
    );
  }
}

Timers.defaultProps = {
  workflows: [],
};

Timers.propTypes = {
  connect: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    apiToken: PropTypes.string,
  }).isRequired,
  workflows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    templateId: PropTypes.string,
  })),
  workflowTemplates: PropTypes.arrayOf(PropTypes.shape({
    // TODO: Change the API to send back a string here
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

function mapStateToProps(state) {
  const { workflows, workflowTemplates } = state;

  return {
    workflows,
    workflowTemplates,
  };
}

const mapDispatchToProps = dispatch => ({
  connect: user => Services.Connection.connect(user, dispatch),
});

export default connectRedux(mapStateToProps, mapDispatchToProps)(withAuthentication(Timers));

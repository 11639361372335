import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import alertActions from "../../actions/alert";
import { errorMessage } from "../../helpers/errorHandler";
import userActions from "../../actions/user";

import Button from "../Button";
import EmailInput from "../EmailInput";
import H1 from "../H1";
import Label from "../Label";
import PasswordInput from "../PasswordInput";
import PrimaryText from "../PrimaryText";

export class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailAddress: "",
      password: "",
    };
  }

  handleEmailAddressChange = (emailAddress) => {
    this.setState({ emailAddress });
  }

  handlePasswordChange = (password) => {
    this.setState({ password });
  }

  handleSubmit = () => {
    const { login } = this.props;

    login(this.state);
  }

  render() {
    return (
      <React.Fragment>
        <H1>Login</H1>
        <Label>Email address:</Label>
        <EmailInput onChangeText={this.handleEmailAddressChange} />
        <Label>Password:</Label>
        <PasswordInput onChangeText={this.handlePasswordChange} />
        <Button onClick={this.handleSubmit}>
          <PrimaryText>Login</PrimaryText>
        </Button>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, props) => ({
  login: (params) => {
    const { onLogin } = props;
    const { emailAddress, password } = params;
    dispatch(userActions.login(emailAddress, password)).then((user) => {
      const { firstName } = user;
      dispatch(alertActions.success(`Welcome back ${firstName}`));
      onLogin(user);
    }, (error) => {
      dispatch(alertActions.error(errorMessage(error)));
    });
  },
});

export default connect(null, mapDispatchToProps)(Login);

import { mutation } from "urql";

const ConnectMutation = () => mutation(`
  mutation {
    connect {
      id
      url
      token
    }
  }
`);

export default ConnectMutation;

import React from "react";
import styled from "styled-components";

import Col from "../Col";
import Grid from "../Grid";
import H3 from "../H3";
import Row from "../Row";

const Copyright = styled.div`
  color: #B8B2A7;
  font-family: ${props => props.theme.font.body};
  padding: 1em 0;
`;

const SectionHeader = styled(H3)`
  color: #B8B2A7;
  font-family: ${props => props.theme.font.body};
  text-transform: uppercase;
`;

const SectionList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0.5em 0 1em;
  text-transform: uppercase;

  a {
    color: ${props => props.theme.color.black};
    font-weight: bold;
    text-decoration: none;
  }
`;

const Tip = styled.div`
  color: #B8B2A7;
  padding: 1em 0;
`;

const MarketingFooter = () => {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentDay = days[currentDate.getDay()];

  return (
    <Grid>
      <Row>
        <Col xs>
          <SectionHeader>Company</SectionHeader>
          <SectionList>
            <li><a href="http://standup.fm" target="_blank" rel="noopener noreferrer">Podcast</a></li>
          </SectionList>
        </Col>
      </Row>
      <Row>
        <Col xs>
          <Row center="xs">
            <Copyright>
              Copyright &copy;2018-
              {currentYear}
              {" "}
              Chronic.
            </Copyright>
          </Row>
          <Row center="xs">
            <Tip>
              <span role="img" aria-label="cheers">🍺</span>
              {" "}
              <em>
                Here&apos;s to focusin&apos; on this lovely
                {" "}
                {currentDay}
                .
              </em>
            </Tip>
          </Row>
        </Col>
      </Row>
    </Grid>
  );
};

export default MarketingFooter;

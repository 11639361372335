import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";

import AuthHeader from "../AuthHeader";
import Col from "../Col";
import Grid from "../Grid";
import LogoLink from "../LogoLink";
import Row from "../Row";

import { withAuthentication } from "../../enhancers/withAuthentication";

export const AppLayout = ({
  alert, isLoggedIn, isProtected, component: Component, ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      ((isProtected && isLoggedIn) || !isProtected) ? (
        <Grid>
          <Row>
            <Col xs={6}>
              <LogoLink href="/">
                Chronic.
              </LogoLink>
            </Col>
            <AuthHeader alert={alert} />
          </Row>
          <Component {...props} />
        </Grid>
      ) : <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
    )}
  />
);

AppLayout.defaultProps = {
  alert: null,
  component: null,
  isLoggedIn: false,
  isProtected: false,
  location: null,
};

AppLayout.propTypes = {
  alert: PropTypes.shape({
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
  ]),
  isLoggedIn: PropTypes.bool,
  isProtected: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default withAuthentication(AppLayout);

import { Client } from "urql";
import { merge } from "lodash";

import { CHRONIC_URL } from "../../constants/service";

export function authHeader({ apiToken }) {
  return { headers: { Authorization: `Bearer ${apiToken}` } };
}

export class GraphQLClient {
  constructor(options) {
    this.client = new Client({
      url: `${CHRONIC_URL}/graphql`,
      fetchOptions: () => merge({}, options),
    });
  }

  execute(query, skipCache = false) {
    return new Promise((resolve, reject) => {
      this.client.executeQuery(query, skipCache).then(
        (response) => {
          const { error } = response;
          if (error) { reject(error); } else { resolve(response); }
        },
      );
    });
  }
}

import React from "react";
import styled from "styled-components";

import HeaderImage from "../HeaderImage";
import avatar from "./avatar.svg";

const Dropdown = styled.a`
  &:after {
    border-width: 6px 6px 0 6px;
    border-style: solid;
    border-color: #000 transparent;
    content: "";
    height: 0;
    margin-left: 8px;
    position: relative;
    top: 12px;
    width: 0;
  }
`;

const UserMenu = () => (
  <Dropdown>
    <HeaderImage src={avatar} alt="avatar" />
  </Dropdown>
);

export default UserMenu;

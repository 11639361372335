import { Platform } from "react-native";
import { css } from "styled-components";

const InputStyles = () => css`
  border-color: #000;
  border-width: 1px;
  ${Platform.select({ web: css`box-sizing: border-box` })};
  font-size: 24px;
  margin-bottom: 10px;
  padding: 3px 5px;
  width: 100%;
`;

export default InputStyles;

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Router } from "react-router-dom";
import { compose } from "redux";
import { ThemeProvider } from "styled-components";
import WebFont from "webfontloader";

import history from "./helpers/history";
import theme from "./helpers/theme";

import GlobalStyle from "./components/GlobalStyle";
import Routes from "./components/Routes";

WebFont.load({
  google: {
    families: ["Pacifico:400", "Raleway:300,400,700", "Lato:300,400,700"],
  },
  classes: false,
});

const App = ({ alert }) => (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <GlobalStyle />
      <Router history={history}>
        <Routes alert={alert} />
      </Router>
    </React.Fragment>
  </ThemeProvider>
);

App.propTypes = {
  alert: PropTypes.shape({
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
};

App.defaultProps = {
  alert: null,
};

function mapStateToProps(state) {
  const { alert } = state;

  return {
    alert,
  };
}

const withConnect = connect(mapStateToProps);
export default compose(withConnect)(App);

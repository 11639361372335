import React from "react";

import Col from "../../components/Col";
import Row from "../../components/Row";
import Timers from "../../containers/Timers";

const TimersPage = () => (
  <Row>
    <Col xs>
      <Timers />
    </Col>
  </Row>
);

export default TimersPage;

import { SET_CONNECTION } from "../constants/connection";

function set(socket) {
  return { type: SET_CONNECTION, connection: { socket } };
}

const connectionActions = {
  set,
};

export default connectionActions;

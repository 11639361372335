import React from "react";

import Col from "../Col";
import Input from "../Input";
import Label from "../Label";
import TextArea from "../TextArea";
import WorkflowBreakInstance from "../WorkflowBreakInstance";
import WorkflowInstance from "../WorkflowInstance";
import WorkflowSchedule from "../WorkflowSchedule";

const TimerForm = () => (
  <Col xs={false} sm={8}>
    <Label>
      Task Name:
    </Label>
    <Input type="text" name="name" />
    <Label>
      Task Description:
    </Label>
    <TextArea rows="20" />
    <WorkflowSchedule>
      <WorkflowInstance xs={1} completed />
      <WorkflowInstance xs={1} completed />
      <WorkflowInstance xs={1} completed />
      <WorkflowBreakInstance xs={1} completed />
      <WorkflowInstance xs={1} completed />
      <WorkflowInstance xs={1} />
      <WorkflowInstance xs={1} />
      <WorkflowBreakInstance xs={1} />
      <WorkflowInstance xs={1} />
      <WorkflowInstance xs={1} />
      <WorkflowInstance xs={1} />
    </WorkflowSchedule>
  </Col>
);

export default TimerForm;

import alertActions from "../actions/alert";
import connectionActions from "../actions/connection";
import ConnectMutation from "../requests/mutations/connect";
import { GraphQLClient, authHeader } from "./graphQLClient";
import workflowsActions from "../actions/workflows";
import WorkflowReducer from "../helpers/workflowReducer";
import workflowTemplatesActions from "../actions/workflowTemplates";

class Connection {
  static connect(user, dispatch) {
    return new GraphQLClient(authHeader(user))
      .execute(ConnectMutation())
      .then(
        (response) => {
          const {
            data: {
              connect: {
                token, url,
              },
            },
          } = response;
          const socket = new WebSocket(url);

          socket.onclose = () => {
            console.log("SOCKET CLOSED");
            dispatch(alertActions.info("CLOSED"));
          };

          socket.onopen = () => {
            console.log("SOCKET OPENED");
            socket.send(JSON.stringify([
              "authenticate",
              { token },
            ]));
          };

          socket.onmessage = (message) => {
            const [event, payload] = JSON.parse(message.data);

            switch (event) {
              case "disconnect": {
                console.log("SOCKET DISCONNECTED");
                dispatch(alertActions.info("DISCONNECTED"));
                break;
              }
              case "accounts:connected": {
                console.log("CONNECTED", payload);
                const workflows = new WorkflowReducer(payload.workflow_templates,
                  payload.workflows).reduce();
                dispatch(workflowsActions.set(workflows));
                dispatch(workflowTemplatesActions.set(payload.workflow_templates));
                dispatch(alertActions.info("CONNECTED"));
                break;
              }
              case "workflows:paused": {
                console.log("PAUSED", payload);
                dispatch(workflowsActions.sync(payload));
                dispatch(alertActions.success("PAUSED"));
                break;
              }
              case "workflows:resumed": {
                console.log("RESUMED", payload);
                dispatch(workflowsActions.sync(payload));
                dispatch(alertActions.success("RESUMED"));
                break;
              }
              case "workflows:started": {
                console.log("STARTED", payload);
                dispatch(workflowsActions.started(payload));
                dispatch(alertActions.success("STARTED"));
                break;
              }
              case "workflows:ticked": {
                console.log("TICK", payload);
                dispatch(workflowsActions.sync(payload));
                break;
              }
              case "unknown": {
                console.log("SOCKET ERRORED");
                break;
              }
              default:
                console.log("UNKNOWN MESSAGE", message);
                break;
            }
          };

          dispatch(connectionActions.set(socket));
        },
      );
  }
}

export default Connection;

import { createGlobalStyle } from "styled-components";

import theme from "../../helpers/theme";

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
  }

  body {
    background-color: ${theme.color.secondary};
    font-family: ${theme.font.body};
    margin: 0;
    padding: 0;
  }

  #root {
    background-color: ${theme.color.neutral};
    border-top: solid 8px ${theme.color.secondary};
    min-height: 100%;
    width: 100%;
  }
`;

export default GlobalStyle;

import { createStore } from "redux";

import { loadState, saveState } from "./localStorage";

import storeMiddleWare from "./store/middleWare";
import rootReducer from "../reducers";

const persistedState = loadState();

const store = createStore(
  rootReducer,
  persistedState,
  storeMiddleWare,
);

store.subscribe(() => {
  const { user } = store.getState();
  saveState({ user });
});

export default store;

import React from "react";

import history from "../../helpers/history";

import Col from "../../components/Col";
import Login from "../../components/Login";
import Row from "../../components/Row";

const LoginPage = () => (
  <Row>
    <Col xs>
      <Login onLogin={() => history.push("/")} />
    </Col>
  </Row>
);

export default LoginPage;

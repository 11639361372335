import React, { Component } from "react";
import { Platform, Text, View } from "react-native";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import TimerFacePauseButton from "../TimerFacePauseButton";
import TimerFacePlayButton from "../TimerFacePlayButton";

const StepName = styled(Text)`
  font-family: ${props => props.theme.font.body};
  ${Platform.select({ web: css`font-size: 0.75rem`, native: css`font-size: 0.75` })};
  ${Platform.select({ web: css`letter-spacing: 0.25rem`, native: css`letter-spacing: 0.25` })};
  text-align: center;
  ${Platform.select({ web: css`text-transform: uppercase` })};
`;

const TemplateName = styled(Text)`
  font-family: ${props => props.theme.font.body};
  font-weight: 700;
  line-height: 2;
  text-align: center;
`;

const Time = styled(Text)`
  font-family: ${props => props.theme.font.body};
  font-size: 96px;
  text-align: center;
`;

const TimerContainer = styled(View)`
  border: solid 1px #000;
  min-height: 600px;
  padding-top: 130px;
`;

class TimerFace extends Component {
  static pad(value) {
    return value.toString().padStart(2, "0");
  }

  displayTime() {
    const { remainingTime } = this.props;
    const remainingSeconds = remainingTime;
    const hours = Math.floor(remainingSeconds / 3600);
    const minutes = Math.floor((remainingSeconds / 60) % 60);
    const seconds = Math.floor(remainingSeconds % 60);
    let displayTime = `${TimerFace.pad(minutes)}:${TimerFace.pad(seconds)}`;

    if (hours > 0) {
      displayTime = `${TimerFace.pad(hours)}:${displayTime}`;
    }

    return displayTime;
  }

  render() {
    const {
      status, onPause, onStart, stepName, templateName,
    } = this.props;

    return (
      <TimerContainer>
        <View>
          <TemplateName>{templateName}</TemplateName>
        </View>
        <View>
          <StepName>{stepName}</StepName>
        </View>
        <View>
          <Time>{this.displayTime()}</Time>
        </View>
        {status && (status === "stopped" || status === "paused")
          && <TimerFacePlayButton onClick={onStart} />
        }
        {status && status === "running"
          && <TimerFacePauseButton onClick={onPause} />
        }
      </TimerContainer>
    );
  }
}

TimerFace.propTypes = {
  onPause: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  remainingTime: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  stepName: PropTypes.string.isRequired,
  templateName: PropTypes.string.isRequired,
};

export default TimerFace;

import {
  CLEAR_ALERT,
  ERROR_ALERT,
  INFO_ALERT,
  SUCCESS_ALERT,
} from "../constants/alert";

export function alert(state = null, action) {
  switch (action.type) {
    case CLEAR_ALERT:
      return null;

    case ERROR_ALERT:
      return {
        ...state,
        type: "error",
        message: action.message,
      };

    case INFO_ALERT:
      return {
        ...state,
        type: "info",
        message: action.message,
      };

    case SUCCESS_ALERT:
      return {
        ...state,
        type: "success",
        message: action.message,
      };

    default:
      return state;
  }
}

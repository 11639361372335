import React from "react";
import PropTypes from "prop-types";

import { SVG, Path } from "../SVG";

const PauseSVG = ({ height, width }) => (
  <SVG height={height} width={width} viewBox="0 0 80 80">
    <Path d="M56.25,11.66h-12.5c-0.464,0-0.84,0.376-0.84,0.84v55c0,0.464,0.376,0.84,0.84,0.84h12.5c0.464,0,0.84-0.376,0.84-0.84v-55   C57.09,12.036,56.714,11.66,56.25,11.66z" />
    <Path d="M36.25,11.66h-12.5c-0.464,0-0.84,0.376-0.84,0.84v55c0,0.464,0.376,0.84,0.84,0.84h12.5c0.464,0,0.84-0.376,0.84-0.84v-55   C37.09,12.036,36.714,11.66,36.25,11.66z" />
  </SVG>
);

PauseSVG.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default PauseSVG;

import styled from "styled-components";
import { Text } from "react-native";

import theme from "../../helpers/theme";

export default styled(Text)`
  font-family: ${theme.font.body};
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;

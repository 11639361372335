import styled from "styled-components";

const TimerFaceButton = styled.button`
  border: none;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 148px;
  margin: 50px auto 0 auto;
  outline: none;
  width: 148px;
`;

export default TimerFaceButton;

const black = "#000";

const theme = {
  font: {
    body: "Lato",
    logo: "Pacifico",
    primary: "Raleway",
  },
  color: {
    alert: {
      info: {
        foreground: "#5bc0de",
      },
      error: {
        foreground: "#f8d7da",
      },
      success: {
        foreground: "#155724",
      },
    },
    black: `${black}`,
    primary: `${black}`,
    secondary: "#BA2525",
    neutral: "#FAF9F7",
    white: "#fff",
  },
};

export default theme;

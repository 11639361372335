import {
  PAUSE_WORKFLOW, RESUME_WORKFLOW, SET_WORKFLOWS, STARTED_WORKFLOW, SYNC_WORKFLOW,
} from "../constants/workflows";

function replaceStatus(state, id, newStatus) {
  return state.map((workflow) => {
    if (workflow.id === id) {
      return {
        ...workflow,
        currentStep: {
          ...workflow.currentStep,
          status: newStatus,
        },
      };
    }
    return workflow;
  });
}

export function workflows(state = [], action) {
  const { type, ...rest } = action;

  switch (type) {
    case PAUSE_WORKFLOW:
      return replaceStatus(state, action.id, "paused");
    case RESUME_WORKFLOW:
      return replaceStatus(state, action.id, "running");
    case SET_WORKFLOWS:
      return action.workflows;
    case STARTED_WORKFLOW:
      return state.concat({ ...rest });
    case SYNC_WORKFLOW:
      return state.map((workflow) => {
        if (workflow.id === action.id) {
          return Object.assign({}, workflow, { ...rest });
        }
        return workflow;
      });
    default:
      return state;
  }
}

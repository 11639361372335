import camelizer from "../helpers/camelizer";
import {
  PAUSE_WORKFLOW, RESUME_WORKFLOW, SET_WORKFLOWS, START_WORKFLOW, STARTED_WORKFLOW, SYNC_WORKFLOW,
} from "../constants/workflows";

function pause(id) {
  return { type: PAUSE_WORKFLOW, id };
}

function resume(id) {
  return { type: RESUME_WORKFLOW, id };
}

function set(workflows) {
  return {
    type: SET_WORKFLOWS,
    workflows,
  };
}

function sync(workflow) {
  return {
    type: SYNC_WORKFLOW,
    ...camelizer.humpDay(workflow),
  };
}

function start(templateId) {
  return { type: START_WORKFLOW, templateId };
}

function started(workflow) {
  return Object.assign({}, sync(workflow), { type: STARTED_WORKFLOW });
}

const workflowsActions = {
  pause,
  resume,
  set,
  start,
  started,
  sync,
};

export default workflowsActions;

import styled from "styled-components";

import Link from "../Link";

const LogoLink = styled(Link)`
  font-family: ${props => props.theme.font.logo}, cursive;
  font-weight: 400;
  font-size: 2.5rem;
  text-transform: lowercase;
`;

export default LogoLink;

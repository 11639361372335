import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS } from "../constants/user";
import { GraphQLClient } from "../services/graphQLClient";
import LoginMutation from "../requests/mutations/login";

function login(emailAddress, password) {
  function failure() {
    return { type: LOGIN_FAILURE, user: null };
  }

  function request() {
    return { type: LOGIN_REQUEST };
  }

  function success(user) {
    return { type: LOGIN_SUCCESS, user };
  }

  return (dispatch) => {
    dispatch(request());

    return new GraphQLClient().execute(LoginMutation(emailAddress, password)).then(
      (response) => {
        const { data: { login: user } } = response;
        dispatch(success(user));
        return Promise.resolve(user);
      },
      (error) => {
        dispatch(failure());
        return Promise.reject(error);
      },
    );
  };
}

const userActions = {
  login,
};

export default userActions;

import React from "react";
import PropTypes from "prop-types";

export const withChangeText = (WrappedComponent) => {
  const ChangeTextHOC = ({ onChangeText, onChange, ...rest }) => {
    const handleOnChange = (event) => {
      if (onChangeText) {
        const { target: { value } } = event;
        onChangeText(value);
      }
    };

    return (
      <WrappedComponent
        onChange={onChange || handleOnChange}
        {...rest}
      />
    );
  };

  ChangeTextHOC.defaultProps = {
    onChange: null,
    onChangeText: null,
  };

  ChangeTextHOC.propTypes = {
    onChange: PropTypes.func,
    onChangeText: PropTypes.func,
  };

  return ChangeTextHOC;
};

import React from "react";
import HeaderImage from "../HeaderImage";
import Link from "../Link";
import notification from "./notification.svg";

const NotificationBell = () => (
  <Link href="/">
    <HeaderImage src={notification} alt="notifications" />
  </Link>
);

export default NotificationBell;

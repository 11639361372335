import styled from "styled-components";

const InlineList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;

  li {
    display: inline;
  }
`;

export default InlineList;

import styled from "styled-components";
import { TextInput } from "react-native";

import InputStyles from "../../styles/Input";

const Input = styled(TextInput)`
  ${InputStyles}
`;

export default Input;

import React from "react";
import PropTypes from "prop-types";

import Col from "../Col";
import Grid from "../Grid";
import Row from "../Row";
import TimerFace from "../TimerFace";
import TimerForm from "../TimerForm";

const Timer = ({
  onStart,
  onPause,
  templateName,
  currentStep: { name, remainingTime, status },
}) => (
  <Grid>
    <Row>
      <Col xs>
        <TimerFace
          onPause={onPause}
          onStart={onStart}
          remainingTime={remainingTime}
          status={status}
          stepName={name}
          templateName={templateName}
        />
      </Col>
      <TimerForm />
    </Row>
  </Grid>
);

Timer.propTypes = {
  onPause: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  templateName: PropTypes.string.isRequired,
  currentStep: PropTypes.shape({
    name: PropTypes.string.isRequired,
    remainingTime: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default Timer;

import { WORKFLOW_TEMPLATES_SET } from "../constants/workflowTemplates";

export function workflowTemplates(state = [], action) {
  const { type } = action;

  switch (type) {
    case WORKFLOW_TEMPLATES_SET:
      return action.workflowTemplates;
    default:
      return state;
  }
}

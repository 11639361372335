const KEY_PREFIX = "chronic:";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(`${KEY_PREFIX}state`);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error("error loading state", err);
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(`${KEY_PREFIX}state`, serializedState);
  } catch (err) {
    console.error("error saving state", err);
  }
};

import React from "react";
import styled from "styled-components";

import Col from "../Col";
import Link from "../Link";
import NavList from "../NavList";

const StyledLink = styled(Link)`
  border-bottom: solid 2px ${props => props.theme.color.secondary};
  color: ${props => props.theme.color.secondary};
  font-family: ${props => props.theme.font.body};
  padding: 8px !important;

  &:hover {
    border-bottom: none;
    color: ${props => props.theme.color.primary};
  }
`;

const MarketingHeader = () => (
  <Col xs={6}>
    <NavList>
      <li>
        <StyledLink href="/login">Log In</StyledLink>
      </li>
    </NavList>
  </Col>
);

export default MarketingHeader;

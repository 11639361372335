import { mutation } from "urql";

const LoginMutation = (emailAddress, password) => mutation(`
  mutation($emailAddress: String!, $password: String!) {
    login(emailAddress: $emailAddress, password: $password) {
      id
      emailAddress
      apiToken
      firstName
      lastName
    }
  }
`, { emailAddress, password });

export default LoginMutation;

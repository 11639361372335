import styled from "styled-components";

import Col from "../Col";

const WorkflowInstance = styled(Col)`
  background: ${({ completed }) => (completed ? "#999" : "transparent")};
  border: solid 1px #000;
  height: 65px;
  margin-bottom: 10px;
  margin-right: 0.5rem;
`;

export default WorkflowInstance;
